import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsProductWeeklyRequest } from '@/types/request/reports-request.types';
import { DateTime, Info } from 'luxon';
import {
  DATE_MODE_DAILY,
  DATE_MODE_CUSTOM_WEEKLY,
  DATEPICKER_FORMAT,
  getDayName,
  getMonthName,
  getWeekName,
  getDateTitle,
  DATE_FORMAT,
  DATE_MODE_MONTHLY,
  DATE_FILTER_FORMAT,
} from '@/helpers/date.helper';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import DatepickerRangeMode from '@/components/ui/inputs/datepicker-range-mode/datepicker-range-mode.vue';
import ShowReportModal from '@/components/features/modals/show-report-modal/show-report-modal.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import SalesTacosChart from '@/components/ui/charts/sales-tacos-chart/sales-tacos-chart.vue';
import PpcOrganicOrdersChart from '@/components/ui/charts/ppc-organic-orders-chart/ppc-organic-orders-chart.vue';
import SalesAcosChart from '@/components/ui/charts/sales-acos-chart/sales-acos-chart.vue';
import PpcSpendSalesChart from '@/components/ui/charts/ppc-spend-sales-chart/ppc-spend-sales-chart.vue';
import SessionsPercentageChart from '@/components/ui/charts/sessions-percentage-chart/sessions-percentage-chart.vue';
import { numberFormatter } from '@/helpers/string.helper';
import ExportXlsxBtn from '@/components/ui/buttons/export-xlsx-btn/export-xlsx-btn.vue';
import { exportToXls, getXlsFilename } from '@/helpers/xlsx.helper';
import AutocompleteInput from '@/components/ui/inputs/autocomplete-input/autocompleteInput.vue';
import ModeSwitcher from '@/components/ui/mode-switcher/mode-switcher.vue';
import ProductDropdownInput from '@/components/ui/inputs/product-dropdown-input/product-dropdown-input.vue';
import ChartsBox from '@/components/ui/charts-box/charts-box.vue';
import ChartsPicker from '@/components/ui/charts-picker/charts-picker.vue';
import SalesTotalChart from '@/components/ui/charts/sales-total-chart/sales-total-chart.vue';
import SalesChartModal from '@/components/features/modals/sales-chart-modal/sales-chart-modal.vue';
import ProductBox from '@/components/ui/product-box/product-box.vue';

const title = 'productWeeklyReport.title';

@Component({
  name: 'ProductWeeklyReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    DatepickerRangeMode,
    ExportXlsxBtn,
    AutocompleteInput,
    ModeSwitcher,
    ProductDropdownInput,
    ChartsBox,
    ChartsPicker,
    SalesTotalChart,
    ProductBox,
  }
})
export default class ProductWeeklyReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();
  saveFilter = true;
  pageFilter: any = {
    brandId: this.$route.query.brandId || null,
  }
  productWeeklyReportDaily: ProductWeeklyType | null = null;
  useBrandFilter = true;

  @Action('getProductWeeklyReport', { namespace: 'reports' })
  getProductWeeklyReport!: (params?: ReportsProductWeeklyRequest) => Promise<ProductWeeklyType>;

  @Getter('getProductWeeklyReport', { namespace: 'reports' })
  productWeeklyReport!: ProductWeeklyType;

  @Mutation('SET_PRODUCT_WEEKLY_REPORT', { namespace: 'reports' })
  setProductWeeklyReport!: (params: ProductWeeklyType | null) => any;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;
    this.pageFilter.type = this.$route.query.type || DATE_MODE_CUSTOM_WEEKLY;

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.productId) {
      this.getProductBrandReportRequest();
    }
  }

  beforeDestroy() {
    this.setProductWeeklyReport(null);
  }

  getProductBrandReportRequest() {
    return this.getProductWeeklyReport(this.preparedFilter)
      .then(response => {
        this.productWeeklyReportDaily = response;
      });
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
    }
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
      type: this.$route.query.type || null,
    };
  }

  get monthsOptions() {
    return [
      { text: this.$t('productWeeklyReport.month'), value: null },
      ...Info.months('long').map(item => ({ text: item, value: item }))
    ];
  }

  get statistics(): any {
    return this.productWeeklyReport ? Object.values(this.productWeeklyReport.statistics) : [];
  }

  get modeOptions () {
    return [
      { text: this.$t('dateMode.daily'), value: DATE_MODE_DAILY },
      { text: this.$t('dateMode.weekly'), value: DATE_MODE_CUSTOM_WEEKLY },
      { text: this.$t('dateMode.monthly'), value: DATE_MODE_MONTHLY },
    ]
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }

  getDateTitle({ from, to }) {
    return this.pageFilter.type === DATE_MODE_DAILY
      ? `${getDayName(from)} ${getMonthName(from)}`
      : getWeekName(from, to);
  }

  getTotal(name: string) {
    return this.productWeeklyReport?.total?.[name] || 0
  }

  get total() {
    return this.productWeeklyReport?.total
  }

  get totalActualDays() {
    return this.productWeeklyReport?.total?.actualDays || 0
  }

  calcTotal(name: string, sign = '') {
    let total = this.getTotal(name);
    const attrs = [
      'acos',
      'tacos',
      'ppcShareInUnits',
      'spendPercent',
      'organicUnitsPercent',
      'ppcSalesPercent',
    ]

    if (attrs.includes(name)) {
      total *= 100;
    }

    return this.valueFormatter(total, sign);
  }

  get organicSalesTotal() {
    const total = this.getTotal('totalSales') - this.getTotal('ppcSales');
    return this.valueFormatter(total);
  }

  get organicSalesPercentTotal() {
    const total = 1 - this.getTotal('ppcSalesPercent');
    return this.valueFormatter(total * 100);
  }

  get organicSalesAvgTotal() {
    const total = this.getTotal('organicUnits') / this.getTotal('actualDays');
    return this.valueFormatter(total);
  }

  get ppcSalesAvgTotal() {
    const total = this.getTotal('ppcUnits') / this.getTotal('actualDays');
    return this.valueFormatter(total);
  }

  get ppcSalesAmountTotal() {
    const total = this.getTotal('totalSales') / this.getTotal('ppcSales');
    return this.valueFormatter(total);
  }

  valueFormatter(value: number, sign = '') {
    const preparedVal = value < 0 ? Math.abs(value) : value;
    const res = numberFormatter(preparedVal, { fractionPartCount: 0 })

    return value < 0 ? `-${sign}${res}` : `${sign}${res}`;
  }

  openReport(name: string) {
    let reportComponent;
    let title;

    switch (name) {
      case 'salesTacosReport':
        reportComponent = SalesTacosChart;
        title = this.$t('productWeeklyReport.salesTacosReport');
        break;
      case 'ppcOrganicOrdersReport':
        reportComponent = PpcOrganicOrdersChart;
        title = this.$t('productWeeklyReport.ppcOrganicOrdersReport');
        break;
      case 'ppcSalesACOSReport':
        reportComponent = SalesAcosChart;
        title = this.$t('productWeeklyReport.ppcSalesACOSReport');
        break;
      case 'ppcSpendPpcSalesReport':
        reportComponent = PpcSpendSalesChart;
        title = this.$t('productWeeklyReport.ppcSpendPpcSalesReport');
        break;
      case 'sessionsUnitSessionReport':
        reportComponent = SessionsPercentageChart;
        title = this.$t('productWeeklyReport.sessionsUnitSessionReport');
        break;
      default:
        reportComponent = null;
        title = '';
    }

    if (reportComponent) {
      this.$modal.show(ShowReportModal, {
        reportComponent,
        title,
        data: this.productWeeklyReportDaily?.statistics || null
      }, MODAL_CONFIG);
    }
  }

  onExport() {
    if (this.productWeeklyReport) {
      const data: any = [];
      const fileName = getXlsFilename(this.$t(title) as string, this.filter);
      Object.values(this.productWeeklyReport.statistics).forEach(statValues => {
        const product = this.products.find(item => item.id === Number(this.filter.productId));
        data.push({
          [this.$t('common.period') as string]: getDateTitle(statValues.from, statValues.to, this.filter.type),
          [this.$t('productWeeklyReport.brand') as string]: this.brands.find(item => item.id === Number(this.pageFilter.brandId))?.displayName || '',
          [this.$t('productWeeklyReport.product') as string]: product?.displayName || product?.name || '',
          [this.$t('productWeeklyReport.asin') as string]: product?.asin || '',
          [this.$t('productWeeklyReport.sales') as string]: `${this.valueFormatter(statValues.totalSales, '$')}`,
          [this.$t('productWeeklyReport.revenuePpcSpend') as string]: `${this.valueFormatter(statValues.revenueWithPpcSpend, '$')}`,
          [this.$t('productWeeklyReport.totalUnits') as string]: this.valueFormatter(statValues.totalUnits),
          [this.$t('productWeeklyReport.organicUnits') as string]: this.valueFormatter(statValues.organicUnits),
          [this.$t('productWeeklyReport.ppcUnits') as string]: this.valueFormatter(statValues.ppcUnits),
          [this.$t('productWeeklyReport.ppcOrders') as string]: this.valueFormatter(statValues.ppcOrders),
          [this.$t('productWeeklyReport.ppcShareUnits') as string]: `${this.valueFormatter(statValues.ppcShareInUnits)}%`,
          [this.$t('productWeeklyReport.actualDays') as string]: this.valueFormatter(statValues.actualDays),
          [this.$t('productWeeklyReport.avrOrdersPerDay') as string]: this.valueFormatter(statValues.ordersPerDays),
          [this.$t('productWeeklyReport.tacos') as string]: `${statValues.tacos ? this.valueFormatter(statValues.tacos * 100) : 0}%`,
          [this.$t('productWeeklyReport.acos') as string]: `${statValues.acos ? this.valueFormatter(statValues.acos * 100) : 0}%`,
          [this.$t('productWeeklyReport.ppcAdSpend') as string]: `${statValues.spend ? this.valueFormatter(statValues.spend, '$') : 0}`,
          [this.$t('productWeeklyReport.ppcSales') as string]: `${statValues.ppcSales ? this.valueFormatter(statValues.ppcSales, '$') : 0}`,
          [this.$t('productWeeklyReport.session') as string]: this.valueFormatter(statValues.session),
          [this.$t('productWeeklyReport.unitSessionPercentage') as string]: `${this.valueFormatter(statValues.unitSessionPercentage)}%`,
        })
      })
      exportToXls(fileName, data);
    }
  }

  openChart(attrName: string) {
    const dateFrom = DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(DATE_FILTER_FORMAT);
    const dateTo = DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(DATE_FILTER_FORMAT);

    this.$modal.show(SalesChartModal, {
      data: this.productWeeklyReport,
      attrName,
      pageTitle: this.$t(title),
      subTitle: `${dateFrom} - ${dateTo}`,
    }, MODAL_CONFIG);
  }

  getTdClass(value: number) {
    return {
      'product-table__cell': true,
      'text-danger': value < 0,
    }
  }

  get selectedProduct() {
    return this.products.find(item => item.id === Number(this.filter.productId))
  }
}
