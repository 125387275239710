import { Component, Prop, Vue } from 'vue-property-decorator';
import StackedBarChart from '@/components/ui/charts/stacked-bar-chart/stacked-bar-chart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLORS } from '@/constants/color.constant';
import { numberFormatter } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';

@Component({
  name: 'PpcSpendSalesChart',
  components: {
    StackedBarChart,
  },
})
export default class PpcSpendSalesChart extends Vue {
  @Prop({ required: true })
  data: any;

  @Prop({ required: false })
  height?: number;

  @Prop({ required: false })
  mobileWidth?: number;

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.data).map(item => item),
      datasets: [
        {
          label: 'PPC sales, $',
          type: 'bar',
          data: Object.values(this.data).map((item: any) => item.ppcSales) as any,
          borderColor: COLORS[0],
          backgroundColor: COLORS[0],
        },
        {
          label: 'PPC ad spend, $',
          type: 'bar',
          data: Object.values(this.data).map((item: any) => -item.spend) as any,
          borderColor: COLORS[1],
          backgroundColor: COLORS[1],
        }
      ]
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach((bar, index) => {
                const value = numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `$ ${value}` : '',
                  bar.x,
                  bar.y - 5
                );
              });
            }
          })
        }
      },
    }
  }

  get chartWidth() {
    return isMobile() ? this.mobileWidth : undefined;
  }

  getChart() {
    return (this.$refs.stackedBar as any).getChart?.();
  }
}
