import { Component, Prop, Mixins } from 'vue-property-decorator';
import SalesChart from '@/components/ui/charts/sales-chart/sales-chart.vue';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import ModalMixin from '@/mixins/modal.mixin';
import { capitalizeFirstLetter } from '@/helpers/string.helper';

@Component({
  name: 'SalesChartModal',
  components: {
    SalesChart,
  }
})
export default class SalesChartModal extends Mixins(ModalMixin) {

  @Prop({
    required: true
  })
  data!: ProductWeeklyType[];

  @Prop({
    required: false,
    default: () => 'amount'
  })
  attrName?: 'amount' | 'count' | 'avg';

  @Prop({
    type: String,
    required: false,
  })
  pageTitle?: string;

  @Prop({
    type: String,
    required: false,
  })
  subTitle?: string;

  get title() {
    return capitalizeFirstLetter(this.attrName || '')
  }

  cancel() {
    this.$emit('close');
  }
}
