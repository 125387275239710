import { Component, Prop, Vue } from 'vue-property-decorator';
import StackedBarChart from '@/components/ui/charts/stacked-bar-chart/stacked-bar-chart.vue';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { COLOR_GREEN_20, COLOR_RED_20, COLOR_YELLOW_20 } from '@/constants/color.constant';
import { isMobile } from '@/helpers/app.helper';
import { DATE_FORMAT, getShowingDate, MD_FORMAT } from '@/helpers/date.helper';
import { ProductWeeklyStatType, ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import { numberFormatter } from '@/helpers/string.helper';

@Component({
  name: 'SalesChart',
  components: {
    StackedBarChart,
  },
})
export default class SalesChart extends Vue {

  @Prop({ required: true })
  data!: ProductWeeklyType;

  @Prop({
    required: false,
    default: () => 'amount'
  })
  attrName!: 'amount' | 'count' | 'avg';

  @Prop({
    required: false,
    default: () => MD_FORMAT
  })
  dateFormat!: string;

  @Prop({ required: false })
  height?: number;

  @Prop({ required: false })
  mobileWidth?: number;

  getOrganicSales(item: ProductWeeklyStatType) {
    switch (this.attrName) {
      case 'amount': return item.totalSales - item.ppcSales;
      case 'count': return item.organicUnits;
      case 'avg': return item.organicUnits / item.actualDays;
    }
  }

  getPpcSales(item: ProductWeeklyStatType) {
    switch (this.attrName) {
      case 'amount': return item.ppcSales;
      case 'count': return item.ppcUnits;
      case 'avg': return item.ppcUnits / item.actualDays;
    }
  }

  getPpcCost(item: ProductWeeklyStatType) {
    switch (this.attrName) {
      case 'amount': return item.spend;
      case 'count': return 0;
      case 'avg': return item.spend / item.actualDays;
    }
  }

  getOrganicSalesPercent(item: ProductWeeklyStatType) {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = 1 - item.ppcSalesPercent;
        break;
      case 'count':
        value = item.organicUnitsPercent;
        break;
      case 'avg':
        value = item.organicUnitsPercent;
        break;
    }

    return value * 100
  }

  getPpcSalesPercent(item: ProductWeeklyStatType) {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = item.ppcSalesPercent;
        break;
      case 'count':
        value = item.ppcUnitsPercent;
        break;
      case 'avg':
        value = item.ppcUnitsPercent;
        break;
    }

    return value * 100
  }

  getPpcCostPercent(item: ProductWeeklyStatType) {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = item.spendPercent;
        break;
      case 'count':
        value = 0;
        break;
      case 'avg':
        value = item.spendPercent;
        break;
    }

    return value * 100
  }

  get chartData(): ChartData {
    const datasets = [
      {
        label: this.$t('productWeeklyReport.ppcSalesChart') as string,
        data: Object.values(this.data.statistics).map(item => this.getPpcSales(item)),
        dataPercent: Object.values(this.data.statistics).map(item => this.getPpcSalesPercent(item)),
        backgroundColor: COLOR_YELLOW_20,
        stack: 'stack-1'
      },
      {
        label: this.$t('productWeeklyReport.organicSalesChart') as string,
        data: Object.values(this.data.statistics).map(item => this.getOrganicSales(item)),
        dataPercent: Object.values(this.data.statistics).map(item => this.getOrganicSalesPercent(item)),
        backgroundColor: COLOR_GREEN_20,
        stack: 'stack-1'
      },
    ] as any

    if (this.attrName !== 'avg') {
      datasets.push({
        label: this.$t('productWeeklyReport.ppcCostChart') as string,
        data: Object.values(this.data.statistics).map(item => this.getPpcCost(item)),
        dataPercent: Object.values(this.data.statistics).map(item => this.getPpcCostPercent(item)),
        backgroundColor: COLOR_RED_20,
        stack: 'stack-2'
      })
    }

    return {
      labels: Object.keys(this.data.statistics).map(item => getShowingDate(item, {
        format: this.dateFormat,
        inputFormat: DATE_FORMAT
      })),
      datasets
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (tooltipItem: TooltipItem<'bar'>) => {
              const value = tooltipItem.raw as number;
              return `${numberFormatter(value, { fractionPartCount: 1 })}%`;
            },
            afterLabel: (tooltipItem: TooltipItem<'bar'>) => {
              const value = (tooltipItem.dataset as any).dataPercent?.[tooltipItem.dataIndex as number] || '';
              const label = (tooltipItem.dataset as any).label;
              return value ? `${label}: ${numberFormatter(value, { fractionPartCount: 0 })}\n` : '';
            }
          },
        },
      },
    }
  }

  get chartWidth() {
    return isMobile() ? this.mobileWidth : undefined;
  }

  getChart() {
    return (this.$refs.stackedBar as any).getChart?.();
  }
}
