import { Component, Prop, Vue } from 'vue-property-decorator';
import PieChart from '@/components/ui/charts/pie-chart/pie-chart.vue';
import { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import {
  COLOR_RED_20,
  COLOR_YELLOW_20,
  COLOR_GREEN_20,
  COLOR_GREY_20
} from '@/constants/color.constant';
import { isMobile } from '@/helpers/app.helper';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';

@Component({
  name: 'SalesTotalChart',
  components: {
    PieChart,
  },
})
export default class SalesTotalChart extends Vue {

  @Prop({ required: true })
  data!: ProductWeeklyType;

  @Prop({
    required: false,
    default: () => 'count'
  })
  attrName!: 'amount' | 'count' | 'avg';

  get chartData(): ChartData {
    let datasets: ChartDataset[];

    switch (this.attrName) {
      case 'amount':
        datasets = [
          {
            data: [this.ppcCost, this.organicSales],
            backgroundColor: [this.colors[2], COLOR_GREY_20],
            weight: 0.5,
          },
          {
            data: [this.ppcSales, this.organicSales],
            backgroundColor: [this.colors[1], this.colors[0]],
            weight: 2,
          },
        ]
        break;
      default:
        datasets = [
          {
            data: [this.ppcSales, this.organicSales],
            backgroundColor: [this.colors[1], this.colors[0]],
            weight: 2,
          },
        ]
    }

    return {
      labels: this.labels,
      datasets
    };
  }

  get organicSales() {
    switch (this.attrName) {
      case 'amount': return this.data.total.totalSales - this.data.total.ppcSales;
      case 'count': return this.data.total.organicUnits;
      case 'avg': return this.data.total.organicUnits / this.data.total.actualDays;
    }
  }

  get ppcSales() {
    switch (this.attrName) {
      case 'amount': return this.data.total.ppcSales;
      case 'count': return this.data.total.ppcUnits;
      case 'avg': return this.data.total.ppcUnits / this.data.total.actualDays;
    }
  }

  get ppcCost() {
    switch (this.attrName) {
      case 'amount': return this.data.total.spend;
      case 'count': return 0;
      case 'avg': return 0;
    }
  }

  get organicSalesPercent() {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = 1 - this.data.total.ppcSalesPercent;
        break;
      case 'count':
        value = this.data.total.organicUnitsPercent;
        break;
      case 'avg':
        value = this.data.total.organicUnitsPercent;
        break;
    }

    return value * 100
  }

  get ppcSalesPercent() {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = this.data.total.ppcSalesPercent;
        break;
      case 'count':
        value = this.data.total.ppcUnitsPercent;
        break;
      case 'avg':
        value = this.data.total.ppcUnitsPercent;
        break;
    }

    return value * 100
  }

  get ppcCostPercent() {
    let value = 0;

    switch (this.attrName) {
      case 'amount':
        value = this.data.total.spendPercent;
        break;
      case 'count':
        value = 0;
        break;
      case 'avg':
        value = this.data.total.spendPercent;
        break;
    }

    return value * 100
  }

  get items() {
    return this.attrName === 'amount'
      ? [
          { name: 'organicSales', value: this.organicSales, percent: this.organicSalesPercent },
          { name: 'ppcSales', value: this.ppcSales, percent: this.ppcSalesPercent },
          { name: 'ppcCost', value: this.ppcCost, percent: this.ppcCostPercent },
        ]
      : [
          { name: 'organicSales', value: this.organicSales, percent: this.organicSalesPercent },
          { name: 'ppcSales', value: this.ppcSales, percent: this.ppcSalesPercent },
        ]
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: (tooltipItem: TooltipItem<'doughnut'>) => {
              return tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1
                ? ''
                : `${tooltipItem.label}: ${tooltipItem.formattedValue}`
            },
            // afterLabel: (tooltipItem: TooltipItem<'bar'>) => {
            //   const data = this.data[tooltipItem.dataIndex!];
            //   const value = Number(data[`${this.attrName}PercentInAllIntervals`]) * 100;
            //   const status = this.getLabelFunc?.(data[this.valueName]) || data[this.valueName];
            //
            //   return `${status}: ${value.toFixed(1)}%\n`;
            // }
          },
        },
      }
    }
  }

  getLabel(value: string) {
    return `${value.slice(0, 20)}${value.length > 20 ? '...' : ''}`;
  }

  getLabelValue(idx: number) {
    return this.items[idx].value;
  }

  getLabelPercent(idx: number) {
    const val = this.items[idx].percent;
    return val.toFixed(1) + '%';
  }

  get sing() {
    return this.attrName === 'amount' ? '$' : '';
  }

  get totalLabelValue() {
    switch (this.attrName) {
      case 'amount':
        return this.data.total.totalSales;
      case 'count':
        return this.organicSales + this.ppcSales;
      case 'avg':
        return this.organicSales + this.ppcSales
    }
  }

  get totalLabelPercent() {
    const percentRes = 100;
    const percent = percentRes.toFixed(1);

    return`${percent}%`
  }

  get labels(): string[] {
    return [
      this.$t('productWeeklyReport.organicSalesChart') as string,
      this.$t('productWeeklyReport.ppcSalesChart') as string,
      this.$t('productWeeklyReport.ppcCostChart') as string,
    ]
  }

  get colors() {
    return [
      COLOR_GREEN_20,
      COLOR_YELLOW_20,
      COLOR_RED_20,
    ]
  }

  getColorStyle(idx: number) {
    return {
      backgroundColor: this.colors[idx]
    }
  }
}
